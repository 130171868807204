<template>
    <div>
        <v-card class="rounded-l elevation-5">
            <v-card-title>
                <v-text-field
                    solo
                    class="border-12"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    style="max-width:400px"
                    @keyup.enter="getPullData()"
                
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
                <v-btn
                small
                color="#fff"
                class="py-5 mr-3"
                @click="getPullData()"
                >
                    <v-icon>mdi-cached</v-icon>
                </v-btn>
                <v-btn
                small
                color="#fff"
                class="py-5 mr-3"
                @click="dialogfilter = true"
                >
                    <v-icon>mdi-filter-variant</v-icon>
                </v-btn>
                <v-btn
                small
                color="success"
                class="py-5"
                @click="printReport()"
                >
                    <v-icon class="mr-2">mdi-printer</v-icon>
                    Print
                </v-btn>
            </v-card-title>
            <v-card-text>
                <div id="divPrintReport" class="p-3">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-simple-table
                                    fixed-header    
                                    dense    
                                    style="border: 1px solid #787878;"                                    
                                >
                                    <thead style="border: 1px solid #787878;">
                                        <tr style="border: 1px solid #787878;">
                                            <th rowspan="2" class="text-center" style="width:25%; border: 1px solid #787878 !important;" >
                                                Nama Program
                                            </th>
                                            <th rowspan="2" class="text-center" style="width:5%; border: 1px solid #787878 !important;" >
                                                Kode
                                            </th>
                                            <th colspan="4" class="text-center" style="width:30%; border: 1px solid #787878 !important;" >
                                                Semester 1
                                            </th>
                                            <th colspan="4" class="text-center" style="width:30%; border: 1px solid #787878 !important;" >
                                                Semester 2
                                            </th>
                                            <th rowspan="2" class="text-center" style="width:10%; border: 1px solid #787878 !important;" >
                                                Total Anggaran
                                            </th>
                                        </tr>
                                        <tr style="border: 1px solid #787878;">
                                        <th class="text-center" style="border: 1px solid #787878 !important;">
                                            n1
                                        </th>
                                        <th class="text-center" style="border: 1px solid #787878 !important;">
                                            a1
                                        </th>
                                        <th class="text-center" style="border: 1px solid #787878 !important;">
                                            b1
                                        </th>
                                        <th class="text-center" style="border: 1px solid #787878 !important;">
                                            Total 1
                                        </th> 
                                        <th class="text-center" style="border: 1px solid #787878 !important;">
                                            n2
                                        </th>
                                        <th class="text-center" style="border: 1px solid #787878 !important;">
                                            a2
                                        </th>
                                        <th class="text-center" style="border: 1px solid #787878 !important;">
                                            b2
                                        </th>
                                        <th class="text-center" style="border: 1px solid #787878 !important;">
                                            Total 2
                                        </th> 
                                        </tr>
                                    </thead>
                                    <tbody v-for="(detail, index) in gl_reports" :key="index">
                                        <tr class="tr_datatable" v-if="detail.ac_sub_parent_data.length > 0">   
                                            <td colspan="11">
                                                <strong> {{ detail.ac_parent }} - {{ detail.ac_parent_desc }} </strong>
                                            </td>
                                        </tr>
                                        <template v-for="(ac_sub_parent) in detail.ac_sub_parent_data" v-if="detail.ac_sub_parent_data.length > 0">
                                            <tr v-if="ac_sub_parent.ac_num_data.length > 0">
                                                <td style="padding-left: 30px;">
                                                    {{ ac_sub_parent.ac_sub_par_one }} - {{ ac_sub_parent.ac_sub_par_one_desc }}
                                                </td>
                                                <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                                            </tr>
                                            <template v-for="(data) in ac_sub_parent.ac_num_data" v-if="ac_sub_parent.ac_num_data.length > 0">
                                                <tr v-if="data.activity_budget_data.length > 0">
                                                    <td style="padding-left: 35px;">
                                                        {{ data.ac_num }} - {{ data.ac_name }}
                                                    </td>
                                                    <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                                                </tr>
                                                <tr v-for="(activity_budget, a) in data.activity_budget_data" :key="a" v-if="data.activity_budget_data.length > 0">
                                                    <td style="padding-left: 50px;">
                                                        {{ a + 1 }} - {{ activity_budget.remark }}
                                                    </td>
                                                    <td>
                                                        {{ activity_budget.budget_type }}
                                                    </td>
                                                    <td class="text-right">
                                                        {{ activity_budget.qty_one }}
                                                    </td>
                                                    <td class="text-right">
                                                        {{ activity_budget.total_activity_one }}
                                                    </td>
                                                    <td class="text-right">
                                                        {{ $store.getters.convertToCurrencyNoFrac(parseFloat(activity_budget.semester_one) / (parseFloat(activity_budget.qty_one) * parseFloat(activity_budget.total_activity_one))) }}
                                                    </td>
                                                    <td class="text-right">
                                                        {{$store.getters.convertToCurrencyNoFrac(parseFloat(activity_budget.semester_one))}}
                                                    </td>
                                                    <td class="text-right">
                                                        {{ activity_budget.qty_two }}
                                                    </td>
                                                    <td class="text-right">
                                                        {{ activity_budget.total_activity_two }}
                                                    </td>
                                                    <td class="text-right">
                                                        {{ $store.getters.convertToCurrencyNoFrac(parseFloat(activity_budget.semester_two) / (parseFloat(activity_budget.qty_two) * parseFloat(activity_budget.total_activity_two))) }}
                                                    </td>
                                                    <td class="text-right">
                                                        {{$store.getters.convertToCurrencyNoFrac(parseFloat(activity_budget.semester_two))}}
                                                    </td>
                                                    <td class="text-right">
                                                        <!-- {{ $store.getters.convertToCurrencyNoFrac((parseFloat(activity_budget.semester_one) * parseFloat(activity_budget.qty_one) * parseFloat(activity_budget.total_activity_one)) +  (parseFloat(activity_budget.semester_two) * parseFloat(activity_budget.qty_two) * parseFloat(activity_budget.total_activity_two))) }} -->
                                                        {{ $store.getters.convertToCurrencyNoFrac(parseFloat(activity_budget.semester_one) +  parseFloat(activity_budget.semester_two)) }}
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                        <!-- <tr v-for="(ac_sub_parent, i) in detail.ac_sub_parent_data" :key="i">
                                            <td style="padding-left: 30px;">
                                                {{ ac_sub_parent.ac_sub_par_one }} - {{ ac_sub_parent.ac_sub_par_one_desc }}
                                            </td>
                                        </tr> -->
                                    </tbody>
                                    <tbody>
                                        <tr class="tr_datatable">
                                            <td colspan="2" style="text-align:left">
                                                <strong>Total</strong>
                                            </td>
                                            <td class="text-right"><strong>{{this.total_n1}}</strong></td>
                                            <td class="text-right"><strong>{{this.total_activity}}</strong></td>
                                            <td class="text-right"><strong>{{$store.getters.convertToCurrencyNoFrac(this.total_semester_one)}}</strong></td>
                                            <td class="text-right"><strong>{{$store.getters.convertToCurrencyNoFrac(this.sub_total_semester_one)}}</strong></td>
                                            <td class="text-right"><strong>{{this.total_n2}}</strong></td>
                                            <td class="text-right"><strong>{{this.total_activity_sem_2}}</strong></td>
                                            <td class="text-right"><strong>{{$store.getters.convertToCurrencyNoFrac(this.total_semester_two)}}</strong></td>
                                            <td class="text-right"><strong>{{$store.getters.convertToCurrencyNoFrac(this.sub_total_semester_two)}}</strong></td>
                                            <td class="text-right"><strong>{{$store.getters.convertToCurrencyNoFrac(this.total_all)}}</strong></td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="dialogfilter"
            persistent
            max-width="600px"
        >
            <v-card class="p-2 rounded-l">
                <v-card-title class="text-center">
                    <v-row>
                        <v-col cols="12">
                            Filter Report
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="mt-2 mb-5">
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">Entity ID <strong style="color:red;">*</strong><span></span></h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="entity_id"
                                    :items="entities"
                                    item-value="entity_id"
                                    item-text="description"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => getBranchOffice(event)"
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">Office ID <strong style="color:red;">*</strong><span></span></h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="office_id"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => [getAcNum(event), getLedgerParent(event)]"
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">AC Par</h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="ac_par"
                                    :items="ac_parents"
                                    item-value="ac_num"
                                    :item-text="item => item.ac_num +' - '+ item.ac_desc"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => [getLedgerSubParent(event), getAcNum(event)]"
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">AC Sub Par</h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="ac_sub_par"
                                    :items="ac_sub_parents"
                                    item-value="ac_sub_par_one"
                                    :item-text="item => item.ac_sub_par_one +' - '+ item.ac_sub_par_one_desc"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => getAcNum(event)"
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">AC Num <span class="red--text"><strong>* </strong></span></h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="ac_num"
                                    :items="ac_nums"
                                    item-value="ac_num"
                                    :item-text="item => item.ac_num +' - '+ item.ac_name"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">Period <strong style="color:red;">*</strong><span></span></h6>
                                <v-menu
                                    ref="modal_filter"
                                    v-model="modal_filter"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date"
                                        label="Period"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date"
                                        type="month"
                                        no-title
                                        @input="modal_filter = false"
                                        :allowed-dates="allowedMonths"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">Bidang</h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="flag_one"
                                    :items="bidangs"
                                    item-value="key_value"
                                    :item-text="item => item.key_value +' - '+ item.str1"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    :disabled="disableFlagOne"
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">Komisi</h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="flag_two"
                                    :items="komisies"
                                    item-value="key_value"
                                    :item-text="item => item.key_value +' - '+ item.str1"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    :disabled="disableFlagTwo"
                                ></v-autocomplete> 
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row justify="center">
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l mr-2" color="#fff" @click="[dialogfilter = false]">Cancel</v-btn>
                        </v-col>
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l text-white" color="#20bf6b" @click="[getPullData(), dialogfilter = false]">Filter</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { appl_constant } from "@/backend-api/appl_constant/index"
import { entity } from "@/backend-api/entity/master"
import { ledger_coa } from "@/backend-api/ledger/coa/index"
import { ledger_parent } from "@/backend-api/ledger/coa/ledger_parent"
import { ledger_sub_parent } from "@/backend-api/ledger/coa/ledger_sub_parent"
import { gl_report } from "@/backend-api/ledger/transaction/gl_report"
import { office } from "@/backend-api/office/index"
import { Money } from 'v-money'

export default {
    components: {
        Money
    },
    data() {
        return {
            money: {
				decimal: '.',
				thousands: ',',
				prefix: '',
				suffix: '',
				precision: 0,
				masked: false
			},
            tab: 'tab-1',
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            text_dialog:'',
            dialog: false,
            breadcumbs: [
                {
                    text: 'Ledger',
                    disabled: false,
                    href: '/admin/ledger',
                },
                {
                    text: 'Ledger Parent',
                    disabled: true,
                }
            ],
            gl_reports: [],
            dialog: false,
            detail: null,
            journals: [],
            dialogfilter: false,
            entities: [],
            entity_id: '',
            offices: [],
            office_id: '',
            menu: false,
            picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date:'',
            year: '',
            ac_nums: [],
            ac_num: '',
            modal_filter: false,
            mod_month: '',
            ac_par: '',
            ac_parents: [],
            ac_sub_par: '',
            ac_sub_parents: [],
            bidangs: [],
            komisies: [],
            flag_one: '',
            disableFlagOne: false,
            flag_two: '',
            disableFlagTwo: false,
        }
    },
    computed:{
        total_n1(){
            var total_n1 = 0
            for (let index = 0; index < this.gl_reports.length; index++) {
                for (let b = 0; b < this.gl_reports[index].ac_sub_parent_data.length; b++) {
                    for (let c = 0; c < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data.length; c++) {  
                        for (let d = 0; d < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data.length; d++) {
                            total_n1 = total_n1 + Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].qty_one);                           
                        }               
                    }
                }
            }
            
            return (total_n1 | 0)
        },
        total_activity(){
            var total_activity = 0
            for (let index = 0; index < this.gl_reports.length; index++) {
                for (let b = 0; b < this.gl_reports[index].ac_sub_parent_data.length; b++) {
                    for (let c = 0; c < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data.length; c++) {  
                        for (let d = 0; d < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data.length; d++) {
                            total_activity = total_activity + Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].total_activity_one);                           
                        }               
                    }
                }
            }
            
            return (total_activity | 0)
        },
        total_semester_one(){
            var total_semester_one = 0
            for (let index = 0; index < this.gl_reports.length; index++) {
                for (let b = 0; b < this.gl_reports[index].ac_sub_parent_data.length; b++) {
                    for (let c = 0; c < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data.length; c++) {  
                        for (let d = 0; d < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data.length; d++) {
                            total_semester_one = total_semester_one + (Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].semester_one) / (Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].qty_one) * Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].total_activity_one)));                           
                        }               
                    }
                }
            }
            
            return (total_semester_one | 0)
        },
        sub_total_semester_one(){
            var sub_total_semester_one = 0
            for (let index = 0; index < this.gl_reports.length; index++) {
                for (let b = 0; b < this.gl_reports[index].ac_sub_parent_data.length; b++) {
                    for (let c = 0; c < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data.length; c++) {  
                        for (let d = 0; d < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data.length; d++) {
                            sub_total_semester_one = sub_total_semester_one + Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].semester_one);                           
                        }               
                    }
                }
            }
            
            return (sub_total_semester_one | 0)
        },
        total_n2(){
            var total_n2 = 0
            for (let index = 0; index < this.gl_reports.length; index++) {
                for (let b = 0; b < this.gl_reports[index].ac_sub_parent_data.length; b++) {
                    for (let c = 0; c < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data.length; c++) {  
                        for (let d = 0; d < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data.length; d++) {
                            total_n2 = total_n2 + Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].qty_two);                           
                        }               
                    }
                }
            }
            
            return (total_n2 | 0)
        },
        total_activity_sem_2(){
            var total_activity_sem_2 = 0
            for (let index = 0; index < this.gl_reports.length; index++) {
                for (let b = 0; b < this.gl_reports[index].ac_sub_parent_data.length; b++) {
                    for (let c = 0; c < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data.length; c++) {  
                        for (let d = 0; d < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data.length; d++) {
                            total_activity_sem_2 = total_activity_sem_2 + Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].total_activity_two);                           
                        }               
                    }
                }
            }
            
            return (total_activity_sem_2 | 0)
        },
        total_semester_two(){
            var total_semester_two = 0
            for (let index = 0; index < this.gl_reports.length; index++) {
                for (let b = 0; b < this.gl_reports[index].ac_sub_parent_data.length; b++) {
                    for (let c = 0; c < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data.length; c++) {  
                        for (let d = 0; d < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data.length; d++) {
                            total_semester_two = total_semester_two + (Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].semester_two) / (Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].qty_two) * Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].total_activity_two)));                           
                        }               
                    }
                }
            }
            
            return (total_semester_two | 0)
        },
        sub_total_semester_two(){
            var sub_total_semester_two = 0
            for (let index = 0; index < this.gl_reports.length; index++) {
                for (let b = 0; b < this.gl_reports[index].ac_sub_parent_data.length; b++) {
                    for (let c = 0; c < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data.length; c++) {  
                        for (let d = 0; d < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data.length; d++) {
                            sub_total_semester_two = sub_total_semester_two + (Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].semester_two));                           
                        }               
                    }
                }
            }
            
            return (sub_total_semester_two | 0)
        },
        total_all(){
            var total_all = 0
            for (let index = 0; index < this.gl_reports.length; index++) {
                for (let b = 0; b < this.gl_reports[index].ac_sub_parent_data.length; b++) {
                    for (let c = 0; c < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data.length; c++) {  
                        for (let d = 0; d < this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data.length; d++) {
                            // total_all = total_all + ((Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].qty_one) * Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].total_activity_one) * Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].semester_one)) + (Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].qty_two) * Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].total_activity_two) * Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].semester_two)));                           
                            total_all = total_all + (Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].semester_one) + (Number(this.gl_reports[index].ac_sub_parent_data[b].ac_num_data[c].activity_budget_data[d].semester_two)));
                        }               
                    }
                }
            }
            
            return (total_all | 0)
        },
    },
    async mounted(){
        // TODO lock bidang dan komisi bila ada
        await this.getApplConstant()
        await this.getEntity()
        await this.getPullData()
        console.log('aku ta')
        // this.$refs.picker.activePicker = 'YEAR'   
    },
    methods: {
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        clear(){
            this.entity_id = ''
            this.office_id = ''
            this.date = ''
            this.year = ''
        },
        async getApplConstant(){
            var respData = await appl_constant.fetchApplConstant("?key_code=BUDGETCYCLE&key_value=MONTH", null, false, false, false)
            if (respData.status === 200) {
                if (respData.data.data.length > 0) {
                    // console.log(respData.data.data);
                    this.mod_month = parseInt(respData.data.data[0].int1)
                }
                // 
            }

            var respDataBidang = await appl_constant.fetchApplConstant("?key_code=BIDANG", null, false, false, false)
            if (respDataBidang.status === 200) {
                this.bidangs = respDataBidang.data.data
                if(this.$store.state.user.bidang != ''){
                    this.flag_one = this.$store.state.user.bidang
                    this.disableFlagOne = true
                }
            }

            var respDataKomisi = await appl_constant.fetchApplConstant("?key_code=KOMISI", null, false, false, false)
            if (respDataKomisi.status === 200) {
                this.komisies = respDataKomisi.data.data
                if(this.$store.state.user.komisi != ''){
                    this.flag_two = this.$store.state.user.komisi
                    this.disableFlagTwo = true
                }
            }

        },
        allowedMonths(val) {
            return parseInt(val.split('-')[1], 10) % Number(this.mod_month) === 1
        },
        async getEntity(){
            var respData = await entity.fetchEntity("", null, false, false, false)
            if (respData.status === 200) {
                this.entities = respData.data
            }
        },
        async getBranchOffice(event){
            if (event === null || event === '') {
                this.office_id = ''
            } else{
                var respData = await office.fetchOffice(`?entity_id=${this.entity_id ? this.entity_id : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.offices = respData.data.data
                }
            }
        },
        async getLedgerParent(event){
            if (event === null || event === '') {
                this.ac_par = ''
            } else{
                var respData = await ledger_parent.fetchLedgerParent(`?entity_id=${this.entity_id ? this.entity_id : ''}&office_id=${this.office_id ? this.office_id : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.ac_parents = respData.data.data
                }
            }
        },
        async getLedgerSubParent(event){
            if (event === null || event === '') {
                this.ac_sub_par = ''
            } else{
                var respData = await ledger_sub_parent.fetchLedgerSubParent(`?entity_id=${this.entity_id ? this.entity_id : ''}&office_id=${this.office_id ? this.office_id : ''}&ac_par=${this.ac_par ? this.ac_par : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.ac_sub_parents = respData.data.data
                }
            }
        },
        async getAcNum(event){
            if (event === null || event === '') {
                this.ac_num = ''
            } else{
                var respData = await ledger_coa.fetchLedgerCoa(`?entity_id=${this.entity_id ? this.entity_id : ''}&office_id=${this.office_id ? this.office_id : ''}&ac_par=${this.ac_par ? this.ac_par : ''}&bidang=${this.$store.state.user.bidang ? this.$store.state.user.bidang : ''}&komisi=${this.$store.state.user.komisi ? this.$store.state.user.komisi : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.ac_nums = respData.data.data
                    // console.log(this.ac_nums);
                }
            }
        },
        save(date) {
            this.$refs.menu.save(date)

            this.$refs.picker.activePicker = 'YEAR'

            this.menu = false

            this.year = new Date(date.substr(0, 4)).toISOString().substr(0, 4)
        },
        async getPullData(){
            this.gl_reports = []
            this.$store.dispatch('setOverlay', true)
            var respData = await gl_report.index(`?entity_id=${this.entity_id ? this.entity_id : ''}&office_id=${this.office_id ? this.office_id : ''}&ac_parent=${this.ac_par ? this.ac_par : ''}&ac_sub_par_one=${this.ac_sub_par ? this.ac_sub_par : ''}&ac_num=${this.ac_num ? this.ac_num : ''}&budget_year=${this.date ? new Date(this.date).toISOString().substr(0, 4).replace(/-/g,"") : new Date().toISOString().substr(0, 4).replace(/-/g,"")}&bidang=${this.flag_one ? this.flag_one : ''}&komisi=${this.flag_two ? this.flag_two : ''}&flag_three=${this.$store.state.user.flag_three ? this.$store.state.user.flag_three : ''}&gl_flag_id=${this.$store.state.user.id_pdt ? '' : ''}`, null, false, false, false)
            if (respData.status === 200) {
                this.gl_reports = respData.data.data
                this.$store.dispatch('setOverlay', false)
            } else{
                this.$store.dispatch('setOverlay', false)
            }
        },
        printReport(){
            const elem = document.getElementById("divPrintReport")
            var domClone = elem.cloneNode(true);
    
            var $printSectionReport = document.getElementById("printSectionReport");
            
            if (!$printSectionReport) {
                var $printSectionReport = document.createElement("div");
                $printSectionReport.id = "printSectionReport";
                document.body.appendChild($printSectionReport);
            }
            
            $printSectionReport.innerHTML = "";
            $printSectionReport.appendChild(domClone);
            window.print();
        },
    },
    watch: {
        // menu (val) {
        //     console.log(val);
        //     val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        // },
    }
}
</script>
  
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}

fieldset.document {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    /* color: rgba(0, 0, 0, 0.6) !important; */
    border: 1px solid #cccccc !important;
}

@media screen {
  #printSectionReport {
      display: none;
  }
}

@media print {
  body * {
    visibility:hidden;
  }
  #printSectionReport, #printSectionReport * {
    visibility:visible;
  }
  #printSectionReport {
    position:absolute;
    left:0;
    top:0;
  }
}

</style>
<style scoped>
td{
    font-size: 11.5px;
    border: 1px solid #787878 !important;
}
</style>